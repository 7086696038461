import { css } from '@emotion/react';
import 'bootstrap/dist/css/bootstrap.css';
import { COLOR_MAP } from '../const';
import { fontPrimary, fontSecondary } from '../fonts';
import { mqL } from '../media-queries';

export const globalStyles = css`
	font-size: 16px;
	body {
		${fontPrimary};
		background-color: ${COLOR_MAP.champagnePink};
		font-size: 1rem;
		color: ${COLOR_MAP.darkSienna};
		line-height: 1.8rem;
	}
	strong,
	b {
		font-weight: 700;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		${fontSecondary};
		font-weight: normal;
		line-height: 1.2;
	}

	h1 {
		font-size: 1.8rem;

		${mqL(`
			font-size: 4rem;
		`)}
	}

	h2 {
		font-size: 1.5rem;

		${mqL(`
			font-size: 3.5rem;
		`)}
	}

	h3 {
		font-size: 1.25rem;
		${mqL(`
			font-size: 2.5rem;
		`)}
	}

	h4,
	h5,
	h6 {
		font-size: 1.2rem;
		${mqL(`
			font-size: 1.8rem;
		`)}
	}
	p {
		font-size: 1rem;
		${mqL(`
			font-size: 1.6rem;
			line-height: 2.4rem;
		`)}
	}
`;
