import * as React from 'react';
import { useSpring, animated } from 'react-spring';
import { styles } from './styles';

export const LOGO_VARIANT = {
	xl: 'xl',
};
export type TLogoVariant = keyof typeof LOGO_VARIANT;
export interface ILogoProps {
	variant?: TLogoVariant;
}
export function Logo({ variant }: ILogoProps) {
	const sprintStyles = useSpring({
		to: [{ opacity: 1 }],
		from: { opacity: 0 },
		delay: 3000,
	});
	return (
		<animated.div style={sprintStyles}>
			<div css={styles(variant).root}>
				<span>DORI</span>
				<span>Art</span>
			</div>
		</animated.div>
	);
}
