import { css } from '@emotion/react';

export const styles = {
	root: css`
		padding: 20px;
	`,
	image: css`
		picture {
			border-radius: 50%;
			padding: 4px;
		}
	`,
};
