export interface IPageDefaultHeader {
	site: { siteMetadata: { description: string; title: string; author: string } };
}

export const PAGE = {
	home: '/',
	imprint: 'impressum',
};

export type TPage = keyof typeof PAGE;

export const ANCHOR = {
	START: 'START',
	ABOUT: 'ABOUT',
	GALERY: 'GALERY',
	CONTACT: 'CONTACT'
};

export type TAnchor = keyof typeof ANCHOR;
