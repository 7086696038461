import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { TAnchor } from '../../configs';
import { styles } from './styles';

export interface IInPageNavigationProps {
	items: {
		label: string;
		anchor: TAnchor;
	}[];
}

export function InPageNavigation({ items }: IInPageNavigationProps) {
	const handleOnClick = (e: React.MouseEvent, anchorId: string) => {
		e.preventDefault();
		scrollTo(anchorId);
	}

	return (
		<ul css={styles.root}>
			{items.map(({ label, anchor }) => (
				<li key={label} css={styles.item}>
					<a href={`#${anchor}`} onClick={e => handleOnClick(e, `#${anchor}`)}>{label}</a>
				</li>
			))}
		</ul>
	);
}
