export const BREAK_POINT = {
	s: 576,
	m: 768,
	l: 992,
	xl: 1200,
} as const;

export type TBreakPoint = keyof typeof BREAK_POINT;

export const Z_INDEX = {
	header: 300,
	sticky: 500,
	modal: 400,
};

export type TZIndex = keyof typeof Z_INDEX;

export const COLOR_MAP = {
	white: '#ffffff',
	black: '#000000',
	champagnePink: '#ebd4cbff',
	pastelPink: '#da9f93ff',
	popstar: '#b6465fff',
	burgundy: '#890620ff',
	darkSienna: '#2c0703ff',
	grey: '#c7c7c2',
};

export type TColorMap = keyof typeof COLOR_MAP;
