import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Modal from 'react-bootstrap/Modal';
import { styles } from './styles';
import { Button } from '../../ui';

interface IImage {
	childImageSharp: { gatsbyImageData: any };
	id: string;
}

export function PaintingGalery() {
	const data = useStaticQuery(graphql`
		query {
			PREVIEW: allFile(filter: { relativePath: { regex: "/bilder//" } }) {
				totalCount
				nodes {
					id
					name
					childImageSharp {
						gatsbyImageData(width: 200, formats: [WEBP, AVIF, AUTO], aspectRatio: 1)
					}
				}
			}
			ORIGINAL: allFile(filter: { relativePath: { regex: "/bilder//" } }) {
				totalCount
				nodes {
					id
					childImageSharp {
						gatsbyImageData(width: 600, formats: [AUTO])
					}
				}
			}
		}
	`);

	// Sort images by name
	data.PREVIEW.nodes.sort((a: any, b: any) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	});

	const paintingsPerPage = 20;
	const [countPaintings, setCountPaintings] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [paintings, setPaintings] = useState<IImage[] | undefined>(undefined);
	const [showButton, setShowButton] = useState<boolean>(true);
	const [originalData, setOriginalData] = useState<IImage | undefined>(undefined);

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = (id: string) => {
		setShow(true);
		const originalSizeImage = data.ORIGINAL.nodes.find((node: IImage) => node.id === id);
		if (originalSizeImage) {
			setOriginalData(originalSizeImage);
		}
	};

	useEffect(() => {
		setCountPaintings(data.PREVIEW.nodes.length);
		setPaintings(data.PREVIEW.nodes.slice(0, currentPage * paintingsPerPage));
	}, [data, currentPage, countPaintings]);

	const handleOnClick = (): void => {
		const paintingsToShow = currentPage * paintingsPerPage;
		const current = currentPage + 1;
		if (paintingsToShow < countPaintings) {
			setCurrentPage(current);
		}
		if (current * paintingsPerPage >= countPaintings) {
			setShowButton(false);
		}
	};

	return (
		<div css={styles.root}>
			<h2>Galerie</h2>
			<div css={styles.paintingContainer}>
				{Array.isArray(paintings) &&
					paintings.map(({ id, childImageSharp }) => (
						<div key={id} css={styles.paintingItem} onClick={() => handleShow(id)}>
							<GatsbyImage image={childImageSharp.gatsbyImageData} alt={id} />
						</div>
					))}
			</div>
			{showButton && (
				<Button
					variant="primary"
					onClick={(element) => {
						element.currentTarget.blur();
						handleOnClick();
					}}
				>
					weitere Bilder laden
				</Button>
			)}
			{originalData && (
				<Modal keyboard show={show} backdrop="static" onHide={handleClose}>
					<Modal.Header closeButton>{` `}</Modal.Header>
					<Modal.Body>
						<GatsbyImage image={originalData.childImageSharp.gatsbyImageData} alt={originalData.id} />
					</Modal.Body>
					<Modal.Footer>
						<Button size="sm" variant="secondary" onClick={handleClose}>
							schliessen
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</div>
	);
}
