import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { styles } from './styles';
import { Logo } from '../../ui';

export function Stage() {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "doris_1.jpeg" }) {
				childImageSharp {
					gatsbyImageData(width: 300, aspectRatio: 1, placeholder: BLURRED)
				}
			}
		}
	`);
	return (
		<div css={styles.root}>
			<div css={styles.image}>
				<GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Doris Müller" />
			</div>
			<Logo />
		</div>
	);
}
