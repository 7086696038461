import { css } from '@emotion/react';
import { COLOR_MAP } from '../../ui/const';

export const styles = {
	root: css`
		list-style: none;
		padding: 0;
	`,
	item: css`
		cursor: pointer;
		& a {
			text-decoration: none;
			color: ${COLOR_MAP.burgundy};
		}
	`,
};
