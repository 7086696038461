import { css } from '@emotion/react';
import { BREAK_POINT } from '../const';

export const styles = {
	root: css`
		box-sizing: border-box;
		width: 100%;
		max-width: ${BREAK_POINT.xl}px;
		margin: 0 auto;
		padding: 0 16px;
	`,
};
