import { css } from '@emotion/react';
import { mqL } from '../../ui/media-queries';

export const styles = {
	root: css`
		padding-bottom: 16px;
		text-algn: center;
	`,
	paintingContainer: css`
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 16px;
	`,
	paintingItem: css`
		width: 150px;
		height: auto;
		margin: 4px;
		${mqL(`
			cursor: pointer;
			width: auto;
		`)}
	`,
};
