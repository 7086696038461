import { css } from '@emotion/react';
import { COLOR_MAP } from '../const';
import { fontSecondary } from '../fonts';
import { TLogoVariant } from './index';

export const styles = (variant?: TLogoVariant) => ({
	root: css`
		padding: 24px 0px;
		background-color: transparent;
		display: flex;
		font-size: ${variant === 'xl' ? `2rem` : `1rem`};
		margin: ${variant === 'xl' ? `1rem` : `0.5rem`} 0;
		position: relative;
		& span:first-of-type {
			font-size: 200%;
			color: ${COLOR_MAP.popstar};
		}
		& span:last-child {
			${fontSecondary};
			font-size: 300%;
			transform: rotate(-12deg);
		}

		&::before {
			content: '';
			position: absolute;
			height: ${variant === 'xl' ? `.3rem` : `.1rem`};
			left: 0;
			right: 0;
			bottom: 0.4rem;
			background-color: ${COLOR_MAP.white};
		}
	`,
});
