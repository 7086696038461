import { TBreakPoint, BREAK_POINT } from './const';

export function mq(breakpoint: TBreakPoint, value: string) {
	return `
		@media (min-width: ${BREAK_POINT[breakpoint]}px) {
			${value}
		}
	`;
}

export function mqXl(value: string) {
	return mq('xl', value);
}

export function mqL(value: string) {
	return mq('l', value);
}

export function mqM(value: string) {
	return mq('m', value);
}

export function mqS(value: string) {
	return mq('s', value);
}
