import { css } from '@emotion/react';
import { mqS } from '../../ui/media-queries';
export const styles = {
	root: css`
		width: 100%;

		${mqS(`
			max-width: 200px;
		`)}
	`,
	variants: {
		ROUNDED: css`
			picture {
				border-radius: 50%;
			}
		`,
	},
	align: {
		LEFT: css`
			float: left;
			margin-right: 8px;
			margin-bottom: 8px;
		`,
		RIGHT: css`
			float: right;
			margin-left: 8px;
			margin-bottom: 8px;
		`,
	},
};
