import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { styles } from './styles';

export interface ITextImageProps {
	name: 'DORIS_1' | 'DORIS_2';
	alt: string;
	variant?: 'ROUNDED';
	align?: 'LEFT' | 'RIGHT';
}
export function TextImage({ name, alt, variant, align }: ITextImageProps) {
	const data = useStaticQuery(graphql`
		fragment imageDetails on File {
			childImageSharp {
				gatsbyImageData(width: 400, formats: [AUTO, WEBP, AVIF], aspectRatio: 1)
			}
		}

		query {
			DORIS_1: file(relativePath: { eq: "doris_1.jpeg" }) {
				...imageDetails
			}
			DORIS_2: file(relativePath: { eq: "doris_2.jpeg" }) {
				...imageDetails
			}
		}
	`);
	return (
		<div css={[styles.root, variant ? styles.variants[variant] : '', align ? styles.align[align] : '']}>
			<GatsbyImage image={data[name].childImageSharp.gatsbyImageData} alt={alt} />
		</div>
	);
}
