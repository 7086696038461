import * as React from 'react';
import { TAnchor } from '../../configs';
import { styles } from './styles';

export interface IContainerProps {
	anchor?: TAnchor;
	children: React.ReactElement | React.ReactElement[];
}

export function Container({ children, anchor }: IContainerProps) {
	return (
		<div id={anchor} css={styles.root}>
			{children}
		</div>
	);
}
