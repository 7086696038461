import { css } from '@emotion/react';
import { COLOR_MAP } from '../const';
import { mqL } from '../media-queries';
export const styles = {
	root: css`
		display: flex;
		position: relative;
	`,
	main: css`
		width: 100%;
		height: 100vh;
		overflow-y: auto;
		background-color: ${COLOR_MAP.grey};
		${mqL(`
			padding-left: 20vw;
			padding-top: 20vh;
			opacity: 0;
			transition: all 0.8s 2s ease-out;

			&.done {
				opacity: 1;
				padding-top: 0;
			}
		`)}
	`,
	stage: css`
		display: none;
		${mqL(`
			background-color: ${COLOR_MAP.burgundy};
			display: flex;
			width: 100vw;
			height: 100vh;
			justify-content: center;
			align-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: 10;
			transition: all 0.8s ease-out;

			&.done {
				width: 20vw;
				background-color: ${COLOR_MAP.pastelPink};
			}
		`)}
	`,
	navi: css`
		display: flex;
		width: 30vw;
		height: 100vh;
		justify-content: center;
		align-content: center;
		align-items: center;
	`,
};
