import React, {useState, useEffect} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Global } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { styles } from './styles';
import { globalStyles } from './globalStyles';
import { IPageDefaultHeader, TAnchor } from '../../configs';
import './normalize.css';
import { InPageNavigation, Stage } from '../../components';

export interface ILayoutProps {
	children: React.ReactElement | React.ReactElement[];
	title?: string;
	description?: string;
	inPageNaviItems?: { label: string; anchor: TAnchor }[];
}
/* eslint-disable complexity */
export function Layout({ title, description, children, inPageNaviItems }: ILayoutProps) {
	const data = useStaticQuery<IPageDefaultHeader>(graphql`
		query {
			site {
				siteMetadata {
					description
					title
					author
				}
			}
		}
	`);

	const [animationDone, setAnimationDone] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => setAnimationDone(true), 2000);
	});

	return (
		<>
			<Helmet>
				<html lang="de" />
				<meta charSet="utf-8" />
				<title>{title || data.site.siteMetadata.title}</title>
				<meta name="description" content={description || data.site.siteMetadata.description} />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />

				<link
					href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Shadows+Into+Light&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
			<Global styles={globalStyles} />
			<div css={styles.root}>
				<div css={styles.stage} className={animationDone ? 'done' : undefined}>
					<Stage />
				</div>
				<div css={styles.main} className={animationDone ? 'done' : undefined}>{children}</div>
				<div css={styles.navi}>{inPageNaviItems && <InPageNavigation items={inPageNaviItems} />}</div>
			</div>
		</>
	);
}
